/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: 500;
      font-size: 1rem;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    line-height: 1;
  }
  td {
    font-size: $default-font-size;
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  &.table-bordered {
    border-top: 1px solid $border-color;
  }
  &.table-header-bg {
    th {
      background: theme-color(light);
      font-weight:bold;
    }
    td {
      color: theme-color(dark);
      .flag-icon {
        width: 2rem;
        line-height: 2rem;
      }
      .progress {
        width: 200px;
        @media screen and (max-width: 991px) { 
          width: 100%;
        }
      }
    }
  }
}
