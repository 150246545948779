

@font-face {
  font-family: 'Karla';
  src: url('../fonts/karla/Karla-Bold.eot');
  src: url('../fonts/karla/Karla-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/karla/Karla-Bold.woff2') format('woff2'),
      url('../fonts/karla/Karla-Bold.woff') format('woff'),
      url('../fonts/karla/Karla-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Karla';
  src: url('../fonts/karla/Karla-Regular.eot');
  src: url('../fonts/karla/Karla-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/karla/Karla-Regular.woff2') format('woff2'),
      url('../fonts/karla/Karla-Regular.woff') format('woff'),
      url('../fonts/karla/Karla-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

