/* Layouts */

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0 0;
          .nav-link {
            display: block;
            padding-left: .5rem;
            padding-right: .5rem;            
            text-align: center;
            position: static;
            margin: 0;
            .menu-title,
            .badge,.menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 0;
              }
              &.menu-arrow {
                display: none; 
              }
            }
            &[aria-expanded] {
              .menu-title {
                border-bottom: 1px solid $sidebar-light-menu-border-color;
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $icon-only-menu-bg-light;
                padding: 0 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);              
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                  box-shadow: -4px 0px 7px 0px rgba(182, 185, 189, 0.25);                                
                }
                &:after {
                  display: none;
                }
              }
              &:hover {
                .menu-title {
                  background: $icon-only-menu-bg-light;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: 0 0;
              background: $sidebar-light-bg;
              @include border-radius(0 0 5px 0);
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.25);
              @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right:$sidebar-width-icon;
                  @include border-radius(0 0 0 5px);
                  box-shadow: -4px 4px 7px 0px rgba(182, 185, 189, 0.25);
              }
            }
          }
        }
        &.sub-menu {
          padding: 0 0;
          background: transparent;
          margin: 0;
          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
              background: transparent;
              &::before {
                display: none;
              }
              &.active {
                color: theme-color(dark);
              }
            }
            &:before {
              display: none;
            }
            border-top: 1px solid $sidebar-light-menu-border-color;

            &:first-child {
              border-top: 1px solid transparent;
            }
          }
        }
      }

      .user-profile {
        margin: 2rem 0 0;
        .user-image {
          img {
            width: 36px;
            height: 36px;
          }
        }
        .user-name {
          display: none;
        }
        .user-designation {
          display: none;
        }
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-icon});
    }
  }
}




