
////////// COLOR SYSTEM //////////

$blue:              #5E50F9;
$indigo:            #6610f2;
$purple:            #6a008a;
$pink:              #E91E63;
$red:               #f96868;
$orange:            #f2a654;
$yellow:            #f6e84e;
$green:             #46c35f;
$teal:              #58d8a3;
$cyan:              #57c7d4;
$black:             #000;
$white:             #ffffff;
$white-smoke:       #f2f7f8;
$violet:            #41478a;
$darkslategray :    #2e383e;
$dodger-blue :      #3498db;


$colors: (
  blue:             $blue,
  indigo:           $indigo,
  purple:           $purple,
  pink:             $pink,
  red:              $red,
  orange:           $orange,
  yellow:           $yellow,
  green:            $green,
  teal:             $teal,
  cyan:             $cyan,
  white:            $white,
  gray:             #434a54,
  gray-light:       #aab2bd,
  gray-lighter:     #e8eff4,
  gray-lightest:    #e6e9ed,
  gray-dark:        #0f1531,
  black:            #000000
);


$theme-colors: (
  primary:         #ec37fc,
  secondary:       #e4e6f6,
  success:         #51c81c,
  info:            #3794fc,
  warning:         #fcb41d,
  danger:          #fc381d,
  light:           #dee5ef,
  dark:            #001737
);

$theme-gradient-colors: (
  primary:         linear-gradient(230deg, #759bff, #843cf6),
  secondary:       linear-gradient(to right, #e7ebf0, #868e96),
  success:         linear-gradient(45deg, #7bffce, #30c93e),
  info:            linear-gradient(to bottom, #0e4cfd, #6a8eff),
  warning:         linear-gradient(135deg, #ffc480, #ff763b),
  danger:          linear-gradient(316deg, #fc5286, #fbaaa2),
  light:           linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%),
  dark:            linear-gradient(to right, #7d7979 0%, #000000 100%)
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f4f7fa;
$border-color: #e4e6f6;

////////// COLOR VARIABLES //////////



////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1:     'Karla', sans-serif;

$default-font-size: .875rem; // 14px as base font size

$text-muted: #666666;
$body-color: #666666;

////////// FONT VARIABLES //////////

///////// FOOTER ////////
$footer-height: 75px;
$footer-bg: #fff;
$footer-color: color(dark);
///////// FOOTER ////////

///////// BUTTONS ////////

$button-fixed-width:        150px;
$btn-padding-y:       0.687rem;
$btn-padding-x:       0.875rem;
$btn-line-height:     1;

$btn-padding-y-xs:    .5rem;
$btn-padding-x-xs:    .75rem;

$btn-padding-y-sm:    .50rem;
$btn-padding-x-sm:    .81rem;

$btn-padding-y-lg:    1rem;
$btn-padding-x-lg:    3rem;

$btn-font-size:       .875rem;
$btn-font-size-xs:    .625rem;
$btn-font-size-sm:    .875rem;
$btn-font-size-lg:    .875rem;

$btn-border-radius:         .1875rem;
$btn-border-radius-xs:      .1875rem;
$btn-border-radius-sm:      .1875rem;
$btn-border-radius-lg:      .1875rem;

///////// BUTTONS ////////



////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size:     .75rem;
$tooltip-padding-y:     .4rem;
$tooltip-padding-x:     .75rem;
$tooltip-border-radius: .375rem;

////////// TOOLTIP VARIABLES //////////



/////////  FORMS /////////

$input-bg:                color(white);
$input-border-radius:     2px;
$input-placeholder-color: #666666;
$input-font-size: .875rem;

$input-padding-y:       .875rem;
$input-padding-x:       1.375rem;
$input-line-height:     1;

$input-padding-y-xs:    .5rem;
$input-padding-x-xs:    .75rem;

$input-padding-y-sm:    .50rem;
$input-padding-x-sm:    .81rem;

$input-padding-y-lg:    .94rem;
$input-padding-x-lg:    1.94rem;

///////// FORMS /////////

////////  DROPDOWNS ///////
$dropdown-color: $body-color;
$dropdown-border-color: $border-color;
$dropdown-divider-bg:   $border-color;
$dropdown-link-color:   $body-color;
$dropdown-header-color: $body-color;
$dropdown-link-hover-bg: #eaeaf1;
////////  DROPDOWNS ///////

//////// TABLES ////////
$table-color: $body-color;
$table-accent-bg: $content-bg;
$table-hover-bg:  #eaeaf1;
$table-cell-padding: 1rem 1rem;
$table-border-color: $border-color;

$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);

//////// TABLES ////////



////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 1.875rem;
$card-padding-y: 1.875rem;
$card-padding-x: 1.875rem;
$card-border-radius: 0;
$card-border-color: $border-color;
$card-box-shadow: none;
$card-title-color: #001737;
$card-description-color: #76838f;
$grid-gutter-width: 1rem;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
$grid-margin: 1rem;
////////// OTHER VARIABLES //////////



////////// BREAD CRUMBS VARIABLES //////////


////////// BREAD CRUMBS VARIABLES //////////


////////   Toggle Switch  //////////////
$toggle-switch-bg: #e9ecef;
////////   CALENDAR  //////////////

////////// SIDEBAR ////////
$sidebar-width-lg: 240px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: #ec37fc;
$sidebar-light-menu-color: #fff;
$sidebar-light-submenu-color: #fff;
$sidebar-light-menu-active-bg: rgba(255, 255, 255, .4);
$sidebar-light-menu-active-color: #fff;
$sidebar-light-menu-hover-bg: rgba(255, 255, 255, .4);
$sidebar-light-menu-hover-color: #fff;
$sidebar-light-submenu-hover-color: #b10dbf;
$sidebar-light-submenu-active-color: #b10dbf;
$sidebar-light-menu-icon-color: #fff;
$sidebar-light-menu-icon-hover-color: #fff;
$sidebar-light-menu-arrow-color: #fff;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;  
$sidebar-light-menu-border-color: rgba(255,255,255,.36);
$sidebar-light-submenu-active-bg: rgba(255,255,255,.4);
$sidebar-light-submenu-hover-bg: rgba(255,255,255,.4);
$sidebar-light-submenu-hover-border: $sidebar-light-bg;
$sidebar-light-submenu-active-border: $sidebar-light-bg;
$sidebar-light-submenu-bg: transparent;



$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding-left: .875rem;
$sidebar-menu-padding-right: .875rem;
$sidebar-menu-padding-top: 1rem;
$sidebar-menu-padding-bottom: 1rem;

$sidebar-submenu-padding: .25rem 0rem 0 0rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: 1rem 4rem;

$sidebar-icon-font-size:  1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 0;

$sidebar-box-shadow: 6px 16px 31px -18px #b7bcd1;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: #b10dbf;


///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: #fff;
$navbar-brand-bg: theme-color(primary);
$navbar-height: 60px;
$navbar-menu-color: #000000;
$navbar-font-size: 1rem;
$navbar-search-color: #666;
$navbar-icon-font-size: .9375rem;
$navbar-color: #000000;
$navbar-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.1);

///////// NAVBAR ////////

